import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Button from "../../modules/Button/button";
import Color from "../../../common/classes/Color";
import useDeviceQuery from "../../hooks/useDeviceQuery";
import useLocationBlocker from "../../hooks/useLocationBlocker";
import {useParams} from "react-router-dom";
import {login, refreshToken} from "@ekultur/authentication";
import Settings from "../../../common/Settings";
import useStore from "../../hooks/useStore";
import SkattejaktService from '../../../common/core/SkattejaktService';

export interface HeaderProps {
    title?: string;
    type?: 'score' | 'title';
    loginButton?: boolean;
    backButtonStyle?: 'home';
    score?: number;
    onCloseClick?: () => void;
}

const Container = styled.header<{ mobile?: boolean }>`
  padding: ${(props) => props?.mobile ? '25px 25px 0 25px' : '75px 75px 0 75px'};
`;

const Content = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Title = styled.h1<{ mobile?: boolean }>`
  margin: ${(props => props?.mobile ? '40px 0 0 0' : '0')};
  font-size: 1.5em;
  font-weight: 900;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-basis: ${(props) => props?.mobile ? '100%' : '33.33%'};
  order: ${(props) => props?.mobile ? 3 : 'unset'}
`;

/**
 *
 * @param title
 * @param children
 * @param type
 * @param loginButton - Display login button at top left
 * @param overviewButton - Display button for overview page at top left
 * @param onCloseClick
 * @param score
 * @constructor
 */

const Header: FC<any> = ({title, children, type, loginButton = false, backButtonStyle, onCloseClick, score}) => {
    const history = useLocationBlocker();
    const { setUser } = useStore();
    const {puzzleId} = useParams<any>();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const setUserRef = useRef<any>();
    setUserRef.current = setUser;
    const isMobile = useDeviceQuery('mobile');
    const size =  isMobile ? 'small' : 'normal';

    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const loginHandler = () => {
        login({
            locationPostLogin: '/',
            loginHost: Settings.getEkulturHost()
        })
    }

    const logoutHandler = () => {
        history.push('/logout');
    }

    const scoreClickHandler = useCallback(() => {
        if (!(history && puzzleId)) return;
        history.push(`/skattejakt/${puzzleId}/highscore`);
    }, [history, puzzleId])


    useEffect(() => {
        let isMounted = true;
        const refresh = async () => {
            await refreshToken({
                apiGateway: Settings.getApiGatewayUrl(),
                onSuccess: () => {
                    if (!isMounted) return;
                    setIsLoggedIn(true);

                },
                onError: () => {
                    if (!isMounted) return;
                    setIsLoggedIn(false);
                }
            })
        }
        refresh().then();

        return () => {
            isMounted = false;
        }
    }, [])

    useEffect( () => {
        if (!isLoggedIn){
            setUserRef?.current?.(null);
            return;
        }
        new SkattejaktService().getUserInfo()
            .then( (data: any) => {
                if (data){
                    setUserRef?.current?.(data)
                }
            })
    }, [isLoggedIn]);

    return (
        <Container mobile={isMobile}>
            <Content>
                {!loginButton && (history.length > 1) ? (backButtonStyle === 'home' ?
                    <Button size={size} label="Tilbake til start" icon="homeIcon" bgColor={Color.WHITE} onClick={goBack}/>
                    :
                    <Button size={size} icon="back" label="Tilbake" bgColor={Color.WHITE} onClick={goBack}/>)
                    :
                        isLoggedIn ?
                            <Button icon="user"
                                    label="Logg ut"
                                    bgColor={Color.BLUE}
                                    onClick={logoutHandler}/>
                            :
                            <Button icon="user"
                                    label="Logg inn"
                                    bgColor={Color.BLUE}
                                    onClick={loginHandler}
                            />

                }
                {type === 'score' ?
                    <Button bgColor={Color.YELLOW}
                            label="Highscore"
                            icon="star"
                            size={size}
                            extended={<>{score}</>}
                            onClick={scoreClickHandler}
                    />
                    :
                    <Title mobile={isMobile}>{title ?? children}</Title>
                }
                <Button size={size}
                        icon="close"
                        label="Avslutt"
                        bgColor={Color.RED}
                        onClick={onCloseClick}/>
            </Content>
        </Container>
    );
};

export default Header;
